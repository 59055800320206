import React from 'react'
import { css } from '@emotion/react'
import { Link } from 'gatsby'

import color from '@/constants/color'
import { LinkType } from '@/typedir/types'

import more_btn from '@/images/more_btn.svg'
import arrow from '@/images/arrow.svg'

export const StyledLink = (props: LinkType): JSX.Element => {
  const { to, href, theme, src, alt, onClick } = props

  return (
    <>
      {props.to && (
        <Link to={to ? to : ''} css={[cssLinkBase, theme]} onClick={onClick}>
          {props.children}
          {props.src && (
            <div>
              <img src={src || more_btn} alt={alt || ''} />
            </div>
          )}
        </Link>
      )}
      {!props.to && (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          css={[cssLinkBase, theme]}
          onClick={onClick}
        >
          {props.children}
          <div>
            <img src={src || arrow} alt={alt || '外部ページへのリンク'} />
          </div>
        </a>
      )}
    </>
  )
}

const cssLinkBase = css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${color.black1};
  div {
    display: flex;
    align-items: center;
    width: 12px;
    margin-left: 6px;
  }
`
