import { css } from '@emotion/react'
import { mq } from '@/components/media/media'
import color from '@/constants/color'

export const cssPro3labColor = css`
  width: 100%;
  max-width: 280px;
  height: 52px;
  font-size: 16px;
  font-weight: 600;
  color: ${color.white1};
  letter-spacing: 1px;
  background: ${color.blue9};
  border: none;
  border-radius: 8px;
  &:hover {
    opacity: 0.85;
  }
  ${mq.pc} {
    max-width: 320px;
    height: 64px;
    font-size: 18px;
    border-radius: 12px;
  }
`

export const cssLink = css`
  width: 100%;
  max-width: 280px;
  height: 52px;
  font-size: 16px;
  color: ${color.white1};
  letter-spacing: 1px;
  background: ${color.blue9};
  border: none;
  border-radius: 8px;
  &:hover {
    opacity: 0.85;
  }
  ${mq.pc} {
    max-width: 320px;
    height: 64px;
    font-size: 18px;
    border-radius: 12px;
  }
`

export const cssGradientButton = css`
  width: 100%;
  max-width: 280px;
  height: 52px;
  font-size: 16px;
  color: ${color.white1};
  letter-spacing: 1px;
  background-image: ${color.blue9};
  border: none;
  border-radius: 8px;
  &:hover {
    opacity: 0.85;
  }
  ${mq.pc} {
    max-width: 320px;
    height: 64px;
    font-size: 18px;
    border-radius: 12px;
  }
`

export const cssShadowButton = css`
  box-shadow: 0 6px 8px ${color.black4};
`

export const cssNewsLink = css`
  z-index: 99;
  justify-content: flex-end;
  margin-top: 20px;
  font-size: 14px;
  letter-spacing: 1.4px;
  ${mq.pc} {
    font-size: 16px;
  }
  div {
    width: 18px;
  }
`

export const cssPolicyLink = css`
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1.2px;
`

export const cssFooterLink = css`
  justify-content: left;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.2px;
`

export const cssShadowGradientButton = css`
  ${cssGradientButton}
  ${cssShadowButton}
`

export const cssPro3labButton = css`
  ${cssPro3labColor}
  ${cssShadowButton}
`
